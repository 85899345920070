<template>
  <div class="q-pa-md">
    <q-dialog v-model="dialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">ATENCIÓN</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          {{ mensaje }}
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="mostrar_link_mp">
      <q-card v-if="creando_link">
        <q-card-section class="q-pt-none">
          <div >
            <h6 class="text-center text-bold q-ma-sm">
              Estamos creando el link de pago
            </h6>
            <p class="text-center text-bold">Aguarde por favor</p>
            <div class="row flex-center">
              <q-circular-progress indeterminate rounded size="50px" color="orange" class="q-ma-md text-center" />
            </div>
          </div>

        </q-card-section>

        <q-card-actions align="right" v-if="(creando_link == false)">
          <q-btn flat label="Cerrar" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
      <qr-wpp :telefono="telefono" :link="link" v-else></qr-wpp>

    </q-dialog>
    <q-dialog v-model="mostrando_usuarios_web">
      <q-card class="q-pa-lg">
        <h6 class="text-center text-bold q-ma-sm">
          Seleccione un usuario web para asociarlo a la compra
        </h6>
        <div class="">
          <div v-for="usuario in usuarios_web" :key="usuario">
            <p> <b>Nombre y apellido:</b> {{ usuario["nombre"] }}</p>
            <p> <b>Email:</b> {{ usuario["email"] }}</p>
            <p> <b>Ciudad:</b> {{ usuario["ciudad"] }}</p>
            <p> <b>Username:</b> {{ usuario["username"] }}</p>
            <q-btn flat label="Seleccionar" color="primary" @click="seleccionar_usuario(usuario.id)" />
            <hr>
          </div>
        </div>
        <q-btn flat label="Cerrar" color="red" v-close-popup />

      </q-card>
    </q-dialog>
    <q-dialog v-model="buscando_usuario" persistent>
      <q-card class="q-pa-lg">
        <h6 class="text-center text-bold q-ma-sm">
          Estamos buscando el usuario web
        </h6>
        <p class="text-center text-bold">Aguarde por favor</p>
        <div class="row flex-center">
          <q-circular-progress indeterminate rounded size="50px" color="orange" class="q-ma-md text-center" />
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="loading" persistent>
      <q-card class="q-pa-lg">
        <h6 class="text-center text-bold q-ma-sm">
          Estamos calculando el valor de la venta
        </h6>
        <p class="text-center text-bold">Aguarde por favor</p>
        <div class="row flex-center">
          <q-circular-progress indeterminate rounded size="50px" color="orange" class="q-ma-md text-center" />
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="verificando_pago" persistent>
      <q-card class="q-pa-lg">
        <h6 class="text-center text-bold q-ma-sm">
          Estamos verificando el estado del pago
        </h6>
        <p class="text-center text-bold">Aguarde por favor</p>
        <div class="row flex-center">
          <q-circular-progress indeterminate rounded size="50px" color="orange" class="q-ma-md text-center" />
        </div>
      </q-card>
    </q-dialog>
    <q-dialog v-model="mostrar_link_de_pago" persistent>
      <q-card class="q-pa-lg">
        <h6 class="text-center text-bold q-ma-sm">
          Copie el link de pago para enviarlo por Redes Sociales
        </h6>
        <q-btn @click="copiar_al_portapapeles" color="primary" label="Copiar" />
        <q-btn @click="mostrar_link_de_pago = false;" color="orange" label="Cerrar" />
      </q-card>
    </q-dialog>
    <q-dialog v-model="finalizando_venta" persistent>
      <q-card class="q-pa-lg">
        <h6 class="text-center text-bold q-ma-sm">Finalizando la venta</h6>
        <p class="text-center text-bold">Aguarde por favor</p>
        <div class="row flex-center">
          <q-circular-progress indeterminate rounded size="50px" color="orange" class="q-ma-md text-center" />
        </div>
      </q-card>
    </q-dialog>

    <q-stepper v-model="step" vertical color="primary" animated v-if="store.venta != null && !first_load">
      <q-step :name="1" title="Datos del comprador" icon="settings" :done="step > 1">
        <h6 class="q-ma-xs text-center">Ingrese los datos del comprador</h6>
        <q-input rounded outlined v-model="nombre" label="Nombre" class="q-mt-md" />
        <q-input rounded outlined v-model="apellido" label="Apellido" class="q-mt-md" />
        <q-input type="number" rounded outlined v-model="telefono" label="Número de teléfono celular (sin 0 y sin 15)"
          class="q-mt-md" />
        <q-input rounded outlined v-model="email"
          hint="Los códigos QR de acceso al evento se enviarán a ésta dirección de e-mail" label="E-mail"
          class="q-mt-md" />

        <q-stepper-navigation>
          <q-btn @click="next()" color="primary" label="Siguiente" />
          <q-btn v-if="puede_precargar_datos" @click="precargar_datos()" color="" label="Precargar datos" />

        </q-stepper-navigation>
      </q-step>

      <q-step :name="2" title="Forma de pago" icon="payments" :done="step > 2">
        <h6 class="q-ma-xs">Seleccione la forma de pago</h6>
        <div class="q-pl-lg q-pt-md">
          <h6 v-if="fpagos.length == 0 && !loading_fpagos" class="q-ma-xs">
            No hay formas de pago disponibles
          </h6>
          <div v-else>

            <q-select rounded outlined v-model="fpago_id" :options="fpagos" label="Forma de Pago" map-options emit-value
              option-value="id" option-label="descripcion" class="q-py-md" />
            <q-select rounded outlined v-model="diferencial_id" :options="diferenciales" label="Cuotas" map-options
              emit-value option-value="diferencial_id" option-label="diferencial_cuota" class="q-py-md"
              v-if="fpago_id != null" />
            <h6>
              Total a pagar:
              <b> {{ store.obtener_pesos(monto_calculado.monto_total) }}</b>
            </h6>
          </div>
        </div>
        <h6 class="q-ma-sm text-red">{{ fpago_text }}</h6>
        <q-stepper-navigation>
          <q-btn flat @click="step = 1" color="primary" label="Volver" class="q-ml-sm" />
          <q-btn @click="next()" color="primary" v-if="(!(diferencial_id == 0 || diferencial_id == null))"
            label="Siguiente" />
        </q-stepper-navigation>
        <q-inner-loading :showing="loading_fpagos">
          <q-circular-progress indeterminate rounded size="50px" color="orange" class="q-ma-md text-center" />
        </q-inner-loading>
      </q-step>
      <q-step :name="3" title="Confirmación" icon="assignment" :done="step > 2">
        <h6 class="q-ma-xs">Confirme que los datos son correctos</h6>
        <div class="q-pl-lg q-pt-md">
          <p>
            Nombre: <b>{{ nombre }}</b>
          </p>
          <p>
            Apellido: <b> {{ apellido }}</b>
          </p>
          <p>
            Teléfono: <b> {{ telefono }}</b>
          </p>
          <p>
            E-mail: <b> {{ email }}</b>
          </p>
          <hr />
          <p>
            Monto entradas:
            <b>{{ store.obtener_pesos(monto_calculado.monto_subtotal) }}</b>
          </p>
          <p>
            Recargo:
            <b>{{ store.obtener_pesos(monto_calculado.monto_diferencial) }}</b>
          </p>

          <h6>
            Total a pagar:
            <b> {{ store.obtener_pesos(monto_calculado.monto_total) }}</b>
          </h6>
        </div>
        <q-stepper-navigation>
          <q-btn flat @click="step = 1" color="primary" label="Volver" class="q-ml-sm" />
          <q-btn @click="next()" color="primary"
            :label="(is_mp) ? 'Confirmar y generar link o QR de pago' : 'Confirmar datos y continuar'" />
        </q-stepper-navigation>
      </q-step>

      <q-step :name="4" title="Pago" icon="paid" :done="step > 4">
        <div>
          <h6 class="text-center text-bold q-ma-sm">
            Aguarde por favor
          </h6>
          <!-- <p class="text-center text-bold">Aguarde por favor</p> -->
          <div class="row flex-center">
            <q-circular-progress indeterminate rounded size="50px" color="orange" class="q-ma-md text-center" />
          </div>
        </div>

      </q-step>
      <q-step :name="5" title="Confirmación de venta" icon="confirmation_number" :done="step > 5">
        <h6>
          Confirme que ha recibido el dinero en efectivo para finalizar la venta
        </h6>
        <q-checkbox class="q-my-md" v-model="confirmacion_visual"
          label="Confirmo que el comprador realizó el pago y tengo el dinero en mi poder" />
        <h6>{{ venta_finalizada_text }}</h6>
        <q-btn @click="finalizar_venta()" :disable="confirmacion_visual == false"
          :color="confirmacion_visual == true ? 'green' : 'gray'" icon="check_circle_outline" label="Finalizar venta" />
      </q-step>
      <q-step :name="6" title="Venta finalizada" icon="check_circle">
        <div>
          <h6 class="text-center text-bold q-ma-sm">{{ mensaje_final }}</h6>
          <p class="text-center text-bold">
            Volvé al inicio para seleccionar alguno de los eventos disponibles
          </p>
          <div class="row flex-center">
            <q-btn @click="router.push({ name: 'home' })" color="green" icon="home" label="Volver al inicio" />
          </div>
        </div>
      </q-step>
    </q-stepper>

    <q-card v-if="store.venta == null && !venta_finalizada" class="q-pa-lg">
      <h6 class="text-center text-bold q-ma-sm">Venta finalizada o expirada</h6>
      <p class="text-center text-bold">
        Volvé al inicio para seleccionar alguno de los eventos disponibles
      </p>
      <div class="row flex-center">
        <q-btn @click="router.push({ name: 'home' })" color="green" icon="home" label="Volver al inicio" />
      </div>
    </q-card>
    <q-card v-if="venta_finalizada" class="q-pa-lg">
      <div>
        <h6 class="text-center text-bold q-ma-sm">{{ mensaje_final }}</h6>
        <p class="text-center text-bold">
          Volvé al inicio para seleccionar alguno de los eventos disponibles
        </p>
        <div class="row flex-center">
          <q-btn @click="router.push({ name: 'home' })" color="green" icon="home" label="Volver al inicio" />
        </div>
      </div>
    </q-card>
  </div>
</template>

<script setup>
// @ is an alias to /src
import { useRouter } from "vue-router";
import { ref, onMounted, watch } from "vue";
import { useEPStore } from "@/store/store";
import axios from "axios";
import QrWpp from "@/QrWpp.vue";

const router = useRouter();
const venta_finalizada = ref(false);
const store = useEPStore();
const nombre = ref("");
const apellido = ref("");
const telefono = ref("");
const email = ref("");
const mensaje_final = ref("");

const link = ref("");

const first_load = ref(true);
const creando_link = ref(false);
const confirmacion_visual = ref(false);
const dialog = ref(false);
const loading = ref(true);
// const errores = ref(false);
const input_copy = ref(null);
const copiando = ref(false);
const mensaje = ref("");
const step = ref(1);
const monto_calculado = ref({});
const link_boton_whatsapp = ref("");
const comprobante = ref(null);
const fpagos = ref([]);
const diferenciales = ref([]);
const is_mp = ref(true)
const puede_precargar_datos = ref(false)
const diferencial_id = ref(null);
const fpago_id = ref(null);
const loading_fpagos = ref(false);
const fpago_text = ref("");
const venta_finalizada_text = ref("");
const usuario_web_id = ref(0)
const payment_id = ref(0)
const buscar_usuario = ref(true)
const buscando_usuario = ref(false)
const mostrando_usuarios_web = ref(false)
const verificando_pago = ref(false)
const pago_realizado = ref(false)
const usuarios_web = ref([])
// const nombre_fpago = ref("");
// const cantidad_cuotas = ref("");
const preference_id = ref(null);
const finalizando_venta = ref(false);
const mostrar_link_de_pago = ref(false);
const mostrar_link_mp = ref(false);
function next() {
  mensaje.value = "";
  switch (step.value) {
    case 1:
      if (email.value.indexOf("@") == -1 || email.value.length < 5) {
        mensaje.value = "Ingrese un email válido";
      }
      if (telefono.value.length != 10) {
        mensaje.value = "Ingrese un número de teléfono válido";
      }
      if (apellido.value.length < 4) {
        mensaje.value = "Ingrese un apellido válido";
      }
      if (nombre.value.length < 4) {
        mensaje.value = "Ingrese un nombre válido";
      }
      if (mensaje.value == "") {
        guardar_credenciales();

      } else {
        dialog.value = true;
      }
      break;
    case 2:
      aplicar_diferencial();
      break;
    case 3:
      if (store.venta != null) {
        if (is_mp.value) {
          step.value = 4
          finalizar_venta()
        } else {
          step.value = 5
        }

        confirmacion_visual.value = false;
      } else {
        dialog.value = true;
        mensaje.value = "La venta expiró";
      }
      break;
    case 4:
      step.value = 5;
      break;
  }
}

const copiado = ref(false)

function copiar_al_portapapeles() {
  copiando.value = true;
  copiado.value = false;

  setTimeout(() => {
    input_copy.value.focus();
    input_copy.value.select();
    document.execCommand('copy');
    copiando.value = false;
    copiado.value = true;
    setTimeout(() => {
      copiado.value = false;

    }, 2000);
  }, 1);
  // copiando.value=false;
}

function precargar_datos() {
  nombre.value = "Mauricio"
  apellido.value = "Entraigas"
  telefono.value = "2920531284"
  email.value = "mauricioentraigas@gmail.com"

}

watch(fpago_id, (newValue, oldValue) => {
  // fires on nested property mutations
  // Note: `newValue` will be equal to `oldValue` here
  // because they both point to the same object!
// console.log(newValue, oldValue);
  diferenciales.value = [];
  diferencial_id.value = null;

  recuperar_cuotas();
});

function finalizar_venta() {
  finalizando_venta.value = true;
  const formData = new FormData();
  // let datos = "";
  let json = {};
  json["datos_entrega"] = {
    "adicionales": email.value,
    "apellido": apellido.value,
    "calle": "-",
    "codigo_postal": "-",
    "departamento": "-",
    "localidad": "-",
    "nombre": nombre.value,
    "numero": "-",
    "piso": "-",
    "provincia": "-",
    "telefono": telefono.value,
  };
  json["entrega"] = "6";
  json["montoentrega"] = "0";
  json["venta"] = store.venta.venta_id;
  let lineas = [];
  let i = 0;
  for (i = 0; i < store.carrito.length; i++) {
    lineas.push({
      linea: `${store.carrito[i].id}`,
      costo: `${store.carrito[i].costo}`,
      sc: `${store.carrito[i].sc}`,
      total: `${store.carrito[i].total}`,
    });
  }
  json["lineas"] = lineas;
  let fpagoo = {};
  fpagoo["credito"] = null//{
  //   // credito_4digitos: "-",
  //   // credito_cupon: "-",
  //   // credito_documento: "-",
  //   // credito_domicilio: "-",
  //   // credito_email: email.value,
  //   // credito_localidad: "-",
  //   // credito_lote: "-",
  //   // credito_nombreapellido: `${nombre.value} ${apellido.value}`,
  //   // credito_telefono: `${telefono.value}`,
  // };
  for (i = 0; i < fpagos.value.length; i++) {
    if (fpagos.value[i].id == fpago_id.value) {
      fpagoo["diferencial"] = `${diferencial_id.value}`;
      fpagoo["fpago_id"] = `${fpago_id.value}`;
      // fpagoo["identificacion"] = `${fpagos.value[i].identificacion}`;
      fpagoo["identificacion"] = `0`;
      fpagoo["monto"] = `${monto_calculado.value.monto_subtotal}`;
      fpagoo["montototal"] = `${monto_calculado.value.monto_total}`;
    }
  }
  json["fpagos"] = [fpagoo];
  let jsons = [];
  jsons.push(json);
  formData.append("venta", JSON.stringify(jsons));
  // formData.append("preference_id", preference_id.value);
  formData.append("is_mp", is_mp.value);
  //[{"lineas":[{"linea":"1231107","costo":"13000.0","sc":"0.0","total":"13000.0"},{"linea":"1231108","costo":"13000.0","sc":"0.0","total":"13000.0"},{"linea":"1231109","costo":"13000.0","sc":"0.0","total":"13000.0"}],"venta":135489,"entrega":"0","montoentrega":"0","fpagos":[{"fpago_id":"92","diferencial":"267","monto":"39000","montototal":"39000","identificacion":"1","credito":{"credito_lote":"123","credito_cupon":"123","credito_4digitos":"123","credito_documento":"123","credito_nombreapellido":"123","credito_domicilio":"123","credito_telefono":"123","credito_localidad":"123","credito_email":"123"}},{"fpago_id":"0","diferencial":null,"monto":"0","montototal":"0","identificacion":"0"}],"datos_entrega":{}}]
  // formData.append("mp_venta", comprobante.value);
  formData.append("venta_id", store.venta.venta_id);
  formData.append("email", email.value);
  formData.append("usuario_web_id", usuario_web_id.value);
  // formData.append("payment_id", payment_id.value);
// console.log(JSON.stringify(jsons));
// console.log(jsons);
  axios
    .post(`${store.host}/webapp/finalizar_venta?${store.obtener_credenciales()}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then(({ data }) => {
    // console.log(data);
      if (data.Result == "error") {
        venta_finalizada_text.value = data.Message;
      } else {
        venta_finalizada.value = true;
        mensaje_final.value = data.Message
        if (is_mp.value == true) {
          generar_link_pago()
        } else {
          store.quitarCompra("¡Venta finalizada exitosamente!");
        }
      }
      finalizando_venta.value = false;
    })
    .catch((e) => {
      finalizando_venta.value = false;
    // console.log(e);
    });
}



function guardar_credenciales() {
  let credenciales = {
    "nombre": nombre.value,
    "apellido": apellido.value,
    "telefono": telefono.value,
    "email": email.value,
  }
  localStorage.setItem("credenciales_" + store.venta.venta_id, JSON.stringify(credenciales));

  axios.post




  buscar_usuario.value = true;
  usuario_web_id.value = 0;
  recuperar_fpagos();
  step.value = 2;

}
function recargar_credenciales() {
  if (store.venta == null){
    return;
  }
  let credenciales = localStorage.getItem("credenciales_" + store.venta.venta_id)
  if (credenciales == null) return;
  try {
    credenciales = JSON.parse(credenciales);

  } catch (e) {
    return console.error(e); // error in the above string (in this case, yes)!
  }
  nombre.value = credenciales["nombre"];
  apellido.value = credenciales["apellido"];
  telefono.value = credenciales["telefono"];
  email.value = credenciales["email"];

}

function recuperar_fpagos() {
  loading_fpagos.value = true;
  axios
    .get(
      `${store.host}/webapp/recuperar_fpagos?token=${store.user.token}&user=${store.user.id}&${store.obtener_credenciales()}`
    )
    .then(({ data }) => {
      fpagos.value = data;
      if (fpagos.value.length != 0) {
        fpago_id.value = fpagos.value[0].id;
        recuperar_cuotas();
      }
      loading_fpagos.value = false;
    // console.log(data);
    })
    .catch((e) => {
    // console.log(e);
    });
}

// function verificar_pago() {
//   verificando_pago.value = true;
//   axios
//     .get(
//       `${store.host}/webapp/verificar_pago_mercadopago?${store.obtener_credenciales()}&total=${monto_calculado.value.monto_total}`
//     )
//     .then(({ data }) => {
//       verificando_pago.value = false;
//       if (data.estado_pago == "pagado") {
//         pago_realizado.value = true
//         payment_id.value = data.payment_id
//         finalizar_venta()
//       }
//     // console.log(data);
//     })
//     .catch((e) => {
//       verificando_pago.value = false;
//     // console.log(e);
//     });
// }

function buscar_usuario_web() {
  buscando_usuario.value = true;

  axios
    .get(
      `${store.host}/webapp/buscar_usuario_web?${store.obtener_credenciales()}&email=${email.value}&numero=${telefono.value}`
    )
    .then(({ data }) => {
      buscando_usuario.value = false;
      usuarios_web.value = data
      if (usuarios_web.value.length > 0) {
        mostrando_usuarios_web.value = true;
      }

    // console.log(data)
    })
    .catch((e) => {
      buscando_usuario.value = false;

    // console.log(e);
    });
}

function seleccionar_usuario(usuario_id) {
  mostrando_usuarios_web.value = false;
  usuario_web_id.value = usuario_id;
}

function aplicar_diferencial() {
  loading_fpagos.value = true;
  axios
    .get(
      `${store.host}/webapp/aplicar_diferencial?token=${store.user.token}&user=${store.user.id}&diferencial=${diferencial_id.value}&valor=${monto_calculado.value.monto_total}`
    )
    .then(({ data }) => {
      if (data.Result == "ok") {
        monto_calculado.value.monto_total = data.Record;
        recalcular();
      } else {
        fpago_text.value = data.Message;
      }
    // console.log(data);
      loading_fpagos.value = false;
    })
    .catch((e) => {
      loading_fpagos.value = false;
      fpago_text.value = "Ocurrió un error";
    // console.log(e);
    });
}

function recuperar_cuotas() {
  loading_fpagos.value = true;
  fpago_text.value = "";
  axios
    .get(
      `${store.host}/webapp/recuperar_cuotas?token=${store.user.token}&user=${store.user.id}&id=${fpago_id.value}`
    )
    .then(({ data }) => {
      if (fpagos.value.length != 0) {
        diferenciales.value = data.Records;
      }
      is_mp.value = (data.Message == "mp")
      loading_fpagos.value = false;
    // console.log(data);
    })
    .catch((e) => {
    // console.log(e);
    });
}

function recalcular() {
  loading.value = true;
  loading_fpagos.value = true;

  let datos = "";
  if (diferencial_id.value != null && diferencial_id.value != 0) {
    datos += "&cuotas=" + diferencial_id.value;
  }
  if (fpago_id.value != null && fpago_id.value != 0) {
    datos += "&fpago_1=" + fpago_id.value;
  }
  axios
    .get(
      `${store.host}/webapp/recalcular?token=${store.user.token}&user=${store.user.id}${datos}`
    )
    .then(({ data }) => {
      first_load.value = false;
      loading.value = false;
      loading_fpagos.value = false;
      monto_calculado.value = data;
      if (step.value == 2) {
        step.value = 3;
        buscar_usuario_web();
      }
    // console.log(data);
    })
    .catch((e) => {
    // console.log(e);
    });
}

function generar_link_pago() {
  creando_link.value = true;
  mostrar_link_mp.value = true;
  axios
    .get(
      `${store.host}/api/generar_link_pago?venta_id=${store.venta.venta_id}&total=${monto_calculado.value.monto_total}&cantidad_entradas=${store.carrito.length}&${store.obtener_credenciales()}`
    )
    .then(({ data }) => {
      creando_link.value = false;
      if (data.init_point == null) {
        dialog.value = true;
        mensaje.value = data.Message;
        step.value = step.value = 2;
      } else {
        link.value = data.init_point;
        store.quitarCompra("¡Link generado exitosamente!");

        // link_boton_whatsapp.value = `https://wa.me/54${telefono.value}?text=${link_boton_whatsapp.value}`;
      }
    // console.log(data);
    })
    .catch((e) => {
      step.value = step.value = 2;
    // console.log(e);
    });
}

onMounted(() => {
  if (store.total_venta == 0) {
    router.push({ name: "carrito" });
    return;
  }
  recargar_credenciales();
  recalcular();
});
</script>
