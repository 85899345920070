<template>
  <q-layout view="lHh Lpr lFf">
    <q-dialog v-model="dialog_logout">
      <q-card>
        <q-card-section>
          <div class="text-h6">Cerrar sesión</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          ¿Está seguro de que quiere cerrar sesión?
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="SI" color="primary" @click="router.push({ name: 'login' })" v-close-popup />
          <q-btn flat label="NO" color="" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="store.cancelando_venta">
      <q-card>
        <q-card-section>
          <div class="text-h6">Aguarde</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Estamos cancelando su venta
        </q-card-section>

        <q-card-actions align="right"> </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog persistent v-model="updateExists">
      <q-card>
        <q-card-section>
          <div class="text-h6">Se detectó una nueva versión de la App</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Recargue para obtener la última versión
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Recargar App" color="green" @click="recargar" />

        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-header elevated class="row flex-center bg-orange" v-if="store.user">
      <q-toolbar class="bg-orange col-xl-7 col-lg-7 col-md-8 col-sm-10 col-xs-11">
        <div @click="router.push({ name: 'home' })">
          <q-img src="./assets/logoEventpass_inicio.png" width="100px" position="left" fit="contain" class="q-pr-sm">
          </q-img>
          <small>{{ store.app_version }}</small>

        </div>
        <q-space />

        <q-btn @click="router.push({ name: 'carrito' })" flat dense round aria-label="Menu"
          icon="shopping_cart"><q-badge floating rounded color="green" :label="store.carrito.length"
            v-if="store.carrito.length != 0" /></q-btn>
        <!-- <q-btn @click="router.push({ name: 'ventas' })" flat dense round aria-label="Mis ventas" icon="list"></q-btn> -->
        <!-- <q-btn @click="q.dark.toggle()" flat dense round aria-label="Mis ventas" icon="list"></q-btn> -->
        <!-- <q-btn @click="dialog_logout = true" flat dense round aria-label="Menu" icon="logout"></q-btn> -->
        <q-btn-dropdown color="orange" dropdown-icon="menu" unelevated>
          <q-list>
            <q-item clickable v-close-popup @click="router.push({ name: 'home' })">
              <q-item-section>
                <q-item-label>Home</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-icon name="home" />
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="router.push({ name: 'ventas' })">
              <q-item-section>
                <q-item-label>Mis ventas</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-icon name="list" />
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="q.dark.toggle()" v-if="false">
              <q-item-section>
                <q-item-label>Tema oscuro ({{ q.dark }})</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-icon name="dark_mode" />
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="dialog_logout = true">
              <q-item-section>
                <q-item-label>Salir</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-icon name="logout" />
              </q-item-section>
            </q-item>

          </q-list>
        </q-btn-dropdown>

      </q-toolbar>
      <q-bar v-if="store.limite_venta != ''"
        class="bg-orange text-center text-weight-bold col-xl-7 col-lg-7 col-md-8 col-sm-10 col-xs-11">
        <div class="col text-center text-weight-bold">
          {{ store.limite_venta }}
        </div>
      </q-bar>
      <q-linear-progress indeterminate v-if="store.loading_carrito" />
    </q-header>
    <q-page-container class="row flex-center {{(q.dark)?'bg-grey-9':'bg-grey-3'}}">
      <div class="col-xl-7 col-lg-7 col-md-8 col-sm-10 col-xs-11">
        <q-card class="q-pa-md q-my-sm">
          <q-breadcrumbs>
            <q-breadcrumbs-el label="Home" icon="home" @click="router.push({ name: 'home' })" />
            <q-breadcrumbs-el label="Evento" icon="event"
              @click="router.push({ name: 'evento', params: { evento_slug: store.evento.evento_slug }, })"
              v-if="store.evento != null" />
            <q-breadcrumbs-el label="Sectores" icon="list" @click="
              router.push({
                name: 'funcion',
                params: {
                  evento_slug: store.evento.evento_slug,
                  funcion_id: store.funcion.id,
                },
              });
            " v-if="store.funcion != null" />
            <q-breadcrumbs-el label="Carrito" icon="shopping_cart" @click="router.push({ name: 'carrito' })"
              v-if="route.name == 'carrito' || route.name == 'checkout'" />
            <q-breadcrumbs-el label="Mis ventas" icon="list" @click="router.push({ name: 'mis_ventas' })"
              v-if="route.name == 'ventas' || route.name == 'ventas'" />
            <q-breadcrumbs-el label="CheckOut" icon="paid" v-if="route.name == 'checkout'" />
          </q-breadcrumbs>
        </q-card>
        <router-view class="q-pt-md"></router-view>
      </div>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { useQuasar } from "quasar";
import { useRouter, useRoute } from "vue-router";
import { useEPStore } from "@/store/store";
import update from './mixins/update'
export default {
  name: "LayoutDefault",
  mixins: [update],
  setup() {
    const q = useQuasar();
    q.dark.set(true);
    const router = useRouter();
    const route = useRoute();
    const expanded = ref(false);
    const store = useEPStore();
    const header = ref(false);
    const dialog_logout = ref(false);
    const reloadingApp = ref(false)
    
    onBeforeMount(() => {
      store.carga_inicial();
      // console.log(q.dark)
      if (store.user != null) {
        header.value = true;
      }
    });
    // console.log(store.user);

    function recargar() {
      reloadingApp.value = true;
      refreshApp()
      location.reload()
    }

    return {
      q,
      leftDrawerOpen: ref(false),
      router,
      expanded,
      store,
      header,
      dialog_logout,
      reloadingApp, route
    };
  },
};
</script>
